export const environment = {
  production: false,
  isDebug: true,
  apiBaseUrl: ``,
  localhostUrls: ``,
  apiUrl: {
    identity: 'https://identitysvcdev.rectitudecs.com/api/',
    access: 'https://acclsvcdev.rectitudecs.com/api/',
    userManagement: 'https://umsvcdev.rectitudecs.com/api/',
    time: 'https://tmsvcdev.rectitudecs.com/api/',
    publicKnowledge:
      'https://geraltaiapidev.rectitudecs.com/public_knowledge_base',
    privateKnowledge: 'https://geraltaiapidev.rectitudecs.com/',
    payments: 'https://paymentsdev.interacers.com/api/',
    dataImport: 'https://impsvcdev.interacers.com/api/',
    dashboard: 'http://localhost:4000/api/',
  },
  isOfflineHosted: false,
  envConstants: {},
  gHLConfig: {
    response_type: 'code',
    redirect_uri: 'https://ersdev.interacers.com/go-oauth',
    client_id: '6630d114de2a6931f0450194-lvmafvdd',
    client_secret: '3956991b-3b37-4df8-82a6-d11b0741d86f',
    grant_type: 'authorization_code',
    user_type: 'Location',
    scope:
      'contacts.write contacts.readonly locations.write locations.readonly oauth.write oauth.readonly users.write users.readonly',
    version: '2021-07-28',
  },
  gHLEndpoints: {
    fetchCode:
      'https://marketplace.leadconnectorhq.com/oauth/chooselocation?response_type=code&redirect_uri=https://ersdev.interacers.com/go-oauth&client_id=6630d114de2a6931f0450194-lvmafvdd&scope=contacts.write contacts.readonly locations.write locations.readonly oauth.write oauth.readonly users.write users.readonly',
    getcAccessToken: 'https://services.leadconnectorhq.com/oauth/token',
    fetchUser: 'https://services.leadconnectorhq.com/users/',
  },
  oAuthConfig: {
    issuer:
      'https://login.microsoftonline.com/e10de6a4-4a75-458c-b062-0bc063d5a370/v2.0',
    strictDiscoveryDocumentValidation: false,
    redirectUri: 'https://ersdev.interacers.com/oauth', //http://localhost:4200/oauth
    clientId: '2323316a-dae9-45cb-ae06-214633985b74',
    scope: 'openid profile email',
    showDebugInformation: true,
  },
};
