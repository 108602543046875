import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ErsIdentityService } from '../services/ers-identity/ers-identity.service';
import { ersConstants } from '@rcs/utils';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErsAuthInterceptor implements HttpInterceptor {
  private service = inject(ErsIdentityService);
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}
  retriveitemFromLs = (key: string): string | null =>
    isPlatformBrowser(this.platformId) ? localStorage.getItem(key) : ``;
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.service.isTokenExpired()) {
      if (request.url.includes('bot_management')) {
        request = request.clone({
          setHeaders: {
            'jwt-authorization': `Bearer ${this.retriveitemFromLs(
              ersConstants.ERS_JWT_TOKEN
            )}`,
            Authorization: this.retriveitemFromLs(ersConstants.BOT_TOKEN) ?? '',
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: request.url.includes('search_with_bot_token')
              ? `${this.retriveitemFromLs(ersConstants.BOT_TOKEN)}`
              : `Bearer ${this.retriveitemFromLs(ersConstants.ERS_JWT_TOKEN)}`,
            'jwt-authorization': request.url.includes('search_with_bot_token')
              ? `Bearer ${this.retriveitemFromLs(ersConstants.ERS_JWT_TOKEN)}`
              : ``,
          },
        });
      }
    }
    return next.handle(request);
  }
}
